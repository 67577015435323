<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link
          class="brand-logo"
          :to="{name:'home'}"
        >
          <!-- logo -->
          <vuexy-logo />
        </b-link>

        <b-card-title class="mb-1">
          ¿Has olvidado tu contraseña? 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Ingrese su ruc y le enviaremos instrucciones para restablecer su contraseña al correo electrónico registrado.
        </b-card-text>

        <!-- form -->
        <validation-observer ref="forgotForm">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
              label="RUC"
              label-for="ruc"
            >
              <validation-provider
                #default="{ errors }"
                name="RUC"
                vid="ruc"
                rules="required|ruc"
              >
                <b-form-input
                  id="ruc"
                  v-model="ruc"
                  :state="errors.length > 0 ? false:null"
                  :formatter="formatterRuc"
                  name="ruc"
                  placeholder="Ejm: 20123456789"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
            >
              Enviar enlace
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <feather-icon icon="ChevronLeftIcon" /> Atrás para iniciar sesión
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      ruc: '',
      // validation
      required,
      email,
    }
  },
  methods: {
    validationForm() {
      console.log(this.$header)
      this.$refs.forgotForm.validate().then(success => {
        if (success) {
          useJwt.forgot({
            ruc: this.ruc,
          })
            .then(response => {
              console.log('response', response)
              this.$router.replace('/ingresar')
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-center',
                    props: {
                      title: 'Se ha enviado el mensaje con éxito',
                      icon: 'SendIcon',
                      variant: 'info',
                      text: 'En breve revise su bandeja de entrada del correo electrónico detallado, para reiniciar su contraseña',
                    },
                  },
                  {
                    timeout: 8000,
                  })
                })
            })
            .catch(error => {
              console.log('errores', error.response.data.error)
              this.$refs.forgotForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
